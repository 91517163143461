import _ from 'lodash';
import moment from 'moment';
import repla from '@/util/dataFilter';
import fetch from '@/util/fetch';
import i18n from '@/locale';
import iter_all from '@/util/format';
import statusCode from '@/assets/constant/statusCode';

export async function getAgentTree(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/agent/tree',
		method: 'post',
		data: {
			reqData,
		},
	});
	if(result.status !== statusCode.STATUS_OK)
		return;

	const tmp = result.result.res;
	tmp.ctx_agent_tree.forEach(elem_1 => {
		let agent_id_list = elem_1.map_ag_code.slice(0, -1).split('-');
		elem_1.agent_id_list = Array.from(agent_id_list);

		agent_id_list.pop();
		elem_1.parent_ag_code = agent_id_list.join('-') + '-';

		const found = tmp.ctx_agent_tree.find(elem_2 => elem_1.parent_ag_code == elem_2.map_ag_code);
		if(found)
			elem_1.parent_ag_username = found.map_ag_username;
	});

	return result;
}
export async function getAgentCompare(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/agent/compare',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const tmp = result.result.res;
		iter_all(tmp);
	}
	return result;
}
