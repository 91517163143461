<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
			v-row
				v-col.chartWrap(cols='12', lg='12', xs='12', v-if='Object.keys(sankeyOption).length !== 0')
					v-chart.chartMoveDown(v-bind:style="{height: 600 + 'px !important' }" @click='getAgent' :option='sankeyOption', ref='sankeyOption', autoresize)
			v-row
				v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(regFunnel).length !== 0')
					v-chart(:option='regFunnel', ref='regFunnel', autoresize)
				//- ChartsSwitch(:firstOption='regLine' :secondOption='regPercent')
				v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(regLine).length !== 0 && percentSwitch === false')
					v-col.switchBoxForEightPix
						span(v-show='percentSwitchShow')
						v-switch(:ripple='false', v-model='percentSwitch', v-show='percentSwitchShow')
					v-chart.chartMoveUp(:option='regLine', ref='regLine', autoresize)
				v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(regPercent).length !== 0 && percentSwitch === true')
					v-col.switchBoxForEightPix
						span(v-show='percentSwitchShow')
						v-switch(:ripple='false', v-model='percentSwitch', v-show='percentSwitchShow')
					v-chart.chartMoveUp(:option='regPercent', ref='regPercent', autoresize)
			v-row.pa-4(lg='4', v-if='table_agent.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.agentSon') }}
				v-col
					VueTabulator(v-model='table_agent.table_data' :options='table_agent.table_opt')
			v-row.pa-4(v-if='table_channel.table_data !== undefined')
				v-col(cols='12')
					b {{ $t('charts.agentChannel') }}
				v-col
					VueTabulator(v-model='table_channel.table_data' :options='table_channel.table_opt')
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import i18n from '@/locale';
import { errorLogger } from '@/util/logger';
import { getAgentCompare, getAgentTree } from '@/api/Agent';
import { exportXlsx } from '@/util/xlsx';
import { mapActions, mapGetters } from 'vuex';
import { sankeyChart } from '@/assets/echarts/ocmsCommon/sankey';
import { userFunnelChart } from '@/assets/echarts/ocmsCommon/funnel';
import { bar, pie, line } from '@/assets/echarts/basicCommon/dataMointor';
import { barPercent } from '@/assets/echarts/ocmsCommon/barPercent';
import chart_option_helper from '@/assets/echarts/chart_option_helper';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TabulatorComponent } from 'vue-tabulator';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	toThousandslsFilter,
	rateOfExchange,
	roundFilter,
	roundOffFilter,
	rdPercent,
	rdPercentOff,
	rdPercentOn,
	roundDecimal,
	roundOff,
	getNaN,
} from '@/util/format';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import statusMsg from '@/util/http';
import COLORS from '@/theme/color';

export default {
	components: {
		'v-chart': ECharts,
		'VueTabulator': TabulatorComponent,
		ChartsSwitch,
		DaliyPicker,
		ExportBtn,
		SingleSelectCurrency,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
			percentSwitchShow: true,
			percentSwitch: false,
			currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			imgSrc: require('@/assets/image/research.png'),
			AID: 0,
			sankeyOption: {},
			regFunnel: {},
			regLine: {},
			regPercent: {},
			table_agent: {},
			table_channel: {},
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			this.percentSwitch = false;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getAgentTree(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = res.result.res;
					// console.log('result', result);
					this.tree = result.ctx_agent_tree;
					this.agentSankey(this.tree);
					this.getExportResult = JSON.parse(JSON.stringify(result));
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async getAgent() {
			this.partClean();
			this.show = !this.show;
			this.loading = !this.loading;
			this.percentSwitch = false;

			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
					agentID: this.AID
				};
				const res = await getAgentCompare(reqData);
				if (res.status === statusCode.STATUS_OK) {
					const result = res.result.res;
					this.recap = result.ctx_agent.ctx_ag_recap[0];
					this.termly = result.ctx_agent.ctx_ag_termly;
					this.regFunnelChart(this.recap);
					this.regChart(this.termly);
					this.regBarChart(this.termly);

					const col_list = [
						{col_name: 'avg_apv_bet', col_show: 'apv_bet', tabulator_column_opt_bar: {width: 170, formatterParams: {color: ['green']}}},
						{col_name: 'avg_house_net_win', col_show: 'house_net_win', tabulator_column_opt_bar: {width: 160, formatterParams: {color: ['#7AB6D8']}}},
						{col_name: 'avg_gm_users', col_show: 'gm_users', tabulator_column_opt_bar: {width: 210, formatterParams: {color: ['#7094D6']}}},
						{col_name: 'gm_stickiness', col_show: 'gm_stickiness', is_ratio: true, tabulator_column_opt_bar: {width: 160, formatterParams: {color: ['#6972D5']}}},
						{col_name: 'avg_dpst_amt', col_show: 'dpst_amt', tabulator_column_opt_bar: {width: 160, formatterParams: {color: ['#7C6AD5']}}},
						{col_name: 'avg_dpst_users', col_show: 'dpst_users', tabulator_column_opt_bar: {width: 210, formatterParams: {color: ['#9A6CD5']}}},
						{col_name: 'avg_reg_users', col_show: 'reg_users', tabulator_column_opt_bar: {width: 230, formatterParams: {color: ['#BA6ED6']}}},
						{col_name: 'avg_gm_cvt_users', col_show: 'gm_cvt_users', tabulator_column_opt_bar: {width: 230, formatterParams: {color: [COLORS.turquoise[3]]}}},
						{col_name: 'gm_cvt_rate', col_show: 'gm_cvt_rate', is_ratio: true, tabulator_column_opt_bar: {width: 200, formatterParams: {color: [COLORS.emerald[3]]}}},
						{col_name: 'avg_dpst_01_cvt_users', col_show: 'dpst_01_cvt_users', tabulator_column_opt_bar: {width: 250, formatterParams: {color: [COLORS.sunflower[3]]}}},
						{col_name: 'dpst_01_cvt_rate', col_show: 'dpst_01_cvt_rate', is_ratio: true, tabulator_column_opt_bar: {width: 220, formatterParams: {color: [COLORS.midnight_blue[3]]}}},
					];

					chart_option_helper.build_table_chart(
						this.table_agent,
						result.ctx_agent_compare[0].ctx_ag_recap,
						[{col_name: 'map_ag_username', col_show: 'map_ag_username', tabulator_column_opt_text: {width: 120}}].concat(col_list),
						'map_ag_username',
					);
					chart_option_helper.build_table_chart(
						this.table_channel,
						result.ctx_agent_compare[0].ctx_channel_recap,
						[{col_name: 'dim_channel_token', col_show: 'dim_channel_token', tabulator_column_opt_text: {width: 120}}].concat(col_list),
						'dim_channel_token',
					);
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async agentSankey(res){

			if(!res.length) {
				this.sankeyOption = {};
				return;
			}

			let tmpLinks = [];
			let tmpNodes = [];
			res.forEach(elem_1 => {
				tmpNodes.push({name: elem_1.map_ag_username});
				if(elem_1.agent_id_list.length < 2)
					return;

				tmpLinks.push({
					source: elem_1.parent_ag_username,
					target: elem_1.map_ag_username,
					value: elem_1.metric_adj,
				});
			});

			this.sankeyOption = sankeyChart({
				title: this.$t('charts.agentOrg'),
				links: tmpLinks,
				nodes: tmpNodes
			});
			this.sankeyOption.series[0].nodeAlign = 'left';
			// this.sankeyOption.tooltip.triggerOn = 'click';
			this.sankeyOption.tooltip.formatter = (params) => {
				let profile = {};
				res.forEach(elem => {
					if(elem.map_ag_username != params.data.name && elem.map_ag_username != params.data.target)
						return;
					profile = elem;
					this.AID = parseInt(elem.agent_id_list[elem.agent_id_list.length - 1]);
				});

				const title = i18n.t('modifier.prefix_avg') + i18n.t(`metric.gm_users`);

				if(params.dataType === 'edge')
					return `${params.data.source} -> ${params.data.target}<br>${title}: ${profile.metric_ori}`;
				else
					return `${i18n.t('metric.map_ag_username')}: ${profile.map_ag_username}<br>${i18n.t('metric.map_ag_code')}: ${profile.map_ag_code}<br>${title}: ${profile.metric_ori}`;
			};
		},
		async regChart(res) {
			const yAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				res.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
				for (let i = 0; i < res.length; i += 1) {
					regUsers.push(res[i].ttl_reg_users);
					loginUsers.push(res[i].ttl_login_cvt_users);
					gamingUsers.push(res[i].ttl_gm_cvt_users);
					dpstUsers.push(res[i].ttl_dpst_01_cvt_users);
					secDpstUsers.push(res[i].ttl_dpst_02_cvt_users);
					yAxisData.push(res[i].dim_ptt);
				}
				this.regLine = await line();
				this.regLine.grid = { top: '22%', bottom: '24%', left: '13%' };
				this.regLine.legend = { top: 'bottom', icon: 'circle', textStyle: { color: '#FFF' }, data: Array.from(new Set(legendData)) };
				// this.regLine.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
				// this.regLine.tooltip.textStyle.color = 'white';
				this.regLine.tooltip.formatter = (params) => {
					let str = `${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
				this.regLine.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
				this.regLine.xAxis.data = Array.from(new Set(yAxisData));
				this.regLine.yAxis.axisLabel.formatter = (value) => {
					return `${nFormatter(value, 1)}`;
				};
				this.regLine.series = [
					{
						name: this.$t('charts.regUsers'),
						type: 'line',
						color: COLORS.dark_water_blue_green,
						data: regUsers,
					},
					{
						name: this.$t('charts.loginUsers'),
						type: 'line',
						color: COLORS.water_blue_green,
						data: loginUsers,
					},
					{
						name: this.$t('charts.gamingUsers'),
						type: 'line',
						color: COLORS.light_green,
						data: gamingUsers,
					},
					{
						name: this.$t('charts.dpstUsers'),
						type: 'line',
						color: COLORS.mustard,
						data: dpstUsers,
					},
					{
						name: this.$t('charts.secondDpst'),
						type: 'line',
						color: COLORS.wisteria[4],
						data: secDpstUsers,
					},
				];
			}
		},
		async regBarChart(res){
			const regUsersData = {},
				loginUsersData = {},
				gamingUsersData = {},
				dpstUsersData = {},
				secDpstUsersData = {};
			const xAxisData = [],
				regUsers = [],
				loginUsers = [],
				gamingUsers = [],
				dpstUsers = [],
				secDpstUsers = [];
			const legendData = [
				this.$t('charts.regUsers'),
				this.$t('charts.loginUsers'),
				this.$t('charts.gamingUsers'),
				this.$t('charts.dpstUsers'),
				this.$t('charts.secondDpst'),
			];
			if (res) {
				for (let i = 0; i < res.length; i += 1) {
					regUsers.push(res[i].ttl_reg_users);
					loginUsers.push(res[i].ttl_login_cvt_users);
					gamingUsers.push(res[i].ttl_gm_cvt_users);
					dpstUsers.push(res[i].ttl_dpst_01_cvt_users);
					secDpstUsers.push(res[i].ttl_dpst_02_cvt_users);
					xAxisData.push(res[i].dim_ptt);
					regUsersData[res[i].dim_ptt] = res[i].ttl_reg_users;
					loginUsersData[res[i].dim_ptt] = res[i].ttl_login_cvt_users;
					gamingUsersData[res[i].dim_ptt] = res[i].ttl_gm_cvt_users;
					dpstUsersData[res[i].dim_ptt] = res[i].ttl_dpst_01_cvt_users;
					secDpstUsersData[res[i].dim_ptt] = res[i].ttl_dpst_02_cvt_users;
				}
				let allPercent = await this.getPercent(regUsers, loginUsers, gamingUsers, dpstUsers, secDpstUsers);
				this.regPercent = await barPercent();
				this.regPercent.grid = { top: '22%', bottom: '24%', left: '17%' };
				this.regPercent.legend = { top: 'bottom', height: 50, textStyle: { color: '#FFF' }, data: Array.from(new Set(legendData)) };
				this.regPercent.legend.selectedMode = false;
				// this.regPercent.tooltip.backgroundColor = 'rgba(50,50,50,0.7)';
				// this.regPercent.tooltip.textStyle.color = 'white';
				this.regPercent.toolbox.show = false;
				this.regPercent.tooltip.formatter = (params) => {
					let dateName = this.$t('charts.date');
					let str = `${dateName}: ${this.$moment(params[0].name).format(this.dateType)}<br/>`;
					if (params[4]) {
						str += `${params[4].marker}${params[4].seriesName}: ${roundOffFilter(regUsersData[params[0].name])}
						(${(
							Number(params[4].value) +
							Number(params[3].value) +
							Number(params[2].value) +
							Number(params[1].value) +
							Number(params[0].value)
						).toFixed(1)}%)<br/>`;
					}
					if (params[3]) {
						str += `${params[3].marker}${params[3].seriesName}: ${roundOffFilter(loginUsersData[params[0].name])}
						(${(Number(params[3].value) + Number(params[2].value) + Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
					}
					if (params[2]) {
						str += `${params[2].marker}${params[2].seriesName}: ${roundOffFilter(regUsersData[params[0].name])}
						(${(Number(params[2].value) + Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
					}
					if (params[1]) {
						str += `${params[1].marker}${params[1].seriesName}: ${roundOffFilter(gamingUsersData[params[0].name])}
						(${(Number(params[1].value) + Number(params[0].value)).toFixed(1)}%)<br/>`;
					}
					if (params[0]) {
						str += ` ${params[0].marker}${params[0].seriesName}: ${roundOffFilter(dpstUsersData[params[0].name])}
						(${Number(params[0].value).toFixed(1)}%)<br/>`;
					}
					return str;
				};
				this.regPercent.xAxis.axisLabel.formatter = (value) => {
					return this.$moment(value).format(this.dateType);
				};
				this.regPercent.xAxis.data = Array.from(new Set(xAxisData));
				this.regPercent.series = [
					{
						name: this.$t('charts.secondDpst'),
						type: 'bar',
						stack: 'all',
						color: '#A569BD',
						data: allPercent.secDpstUsersArr,
					},
					{
						name: this.$t('charts.dpstUsers'),
						type: 'bar',
						stack: 'all',
						color: '#FFDB5C',
						data: allPercent.dpstUsersForBar,
					},
					{
						name: this.$t('charts.gamingUsers'),
						type: 'bar',
						stack: 'all',
						color: '#9FE6B8',
						data: allPercent.gamingUsersForBar,
					},
					{
						name: this.$t('charts.loginUsers'),
						type: 'bar',
						stack: 'all',
						color: '#67E0E3',
						data: allPercent.loginUsersForBar,
					},
					{
						name: this.$t('charts.regUsers'),
						type: 'bar',
						stack: 'all',
						color: '#32C5E9',
						data: allPercent.regUsersForBar,
					},
				];
			}
		},
		async regFunnelChart(res) {
			this.funnelTmpData = {
				title: this.$t('charts.newUsersConversion'),
				subtext: this.$t('charts.avdDailyUsers'),
				legend: [
					this.$t('charts.regUsers'),
					this.$t('charts.loginUsers'),
					this.$t('charts.gamingUsers'),
					this.$t('charts.dpstUsers'),
					this.$t('charts.secondDpst')
				],
				name: this.$t('charts.newUsersConversion'),
				data: {
					[this.$t('charts.regUsers')]: res.avg_reg_users,
					[this.$t('charts.loginUsers')]: res.avg_login_cvt_users,
					[this.$t('charts.gamingUsers')]: res.avg_gm_cvt_users,
					[this.$t('charts.dpstUsers')]: res.avg_dpst_01_cvt_users,
					[this.$t('charts.secondDpst')]: res.avg_dpst_02_cvt_users,
				},
				subTitle: [
					this.$t('charts.avgRegUsers'),
					this.$t('charts.avgLoginUsers'),
					this.$t('charts.avgGamingUsers'),
					this.$t('charts.avgDpstUsers'),
					this.$t('charts.avgSecondDpst')
				],
				subData: [
					res.ttl_reg_users,
					res.ttl_login_cvt_users,
					res.ttl_gm_cvt_users,
					res.ttl_dpst_01_cvt_users,
					res.ttl_dpst_02_cvt_users,
				]
			};
			this.regFunnel = await userFunnelChart(this.funnelTmpData);
			this.regFunnel.series[0].color = [
				COLORS.dark_water_blue_green, COLORS.water_blue_green, COLORS.light_green, COLORS.mustard, COLORS.wisteria[4]
			];
		},
		allClean() {
			this.AID = 0;
			this.sankeyOption = {};
			this.regFunnel = {};
			this.regLine = {};
			this.regPercent = {};
			this.table_agent = {};
			this.table_channel = {};
		},
		partClean() {
			this.regFunnel = {};
			this.regLine = {};
			this.regPercent = {};
			this.ATable = {};
			this.CTable = {};
			this.ATableData = [];
			this.CTableData = [];
		},
		getPercent(regUsers, loginUsers, gamingUsers, dpstUsers, secDpstUsers) {
			let regUsersArr = [],
				loginUsersArr = [],
				gamingUsersArr = [],
				dpstUsersArr = [],
				secDpstUsersArr = [],
				dpstUsersForBar = [],
				gamingUsersForBar = [],
				loginUsersForBar = [],
				regUsersForBar = [];
			for (var i = 0; i < regUsers.length; i++) {
				regUsersArr.push(roundDecimal((regUsers[i] / regUsers[i]) * 100));
				loginUsersArr.push(roundDecimal((loginUsers[i] / regUsers[i]) * 100));
				gamingUsersArr.push(roundDecimal((gamingUsers[i] / regUsers[i]) * 100));
				dpstUsersArr.push(roundDecimal((dpstUsers[i] / regUsers[i]) * 100));
				secDpstUsersArr.push(roundDecimal((secDpstUsers[i] / regUsers[i]) * 100));
				dpstUsersForBar.push(roundDecimal(dpstUsersArr[i] - secDpstUsersArr[i]));
				gamingUsersForBar.push(roundDecimal(gamingUsersArr[i] - dpstUsersArr[i]));
				loginUsersForBar.push(roundDecimal(loginUsersArr[i] - gamingUsersArr[i]));
				regUsersForBar.push(roundDecimal(regUsersArr[i] - loginUsersArr[i]));
			}
			let allDataPercent = {
				regUsersArr,
				loginUsersArr,
				gamingUsersArr,
				dpstUsersArr,
				secDpstUsersArr,
				regUsersForBar,
				loginUsersForBar,
				gamingUsersForBar,
				dpstUsersForBar,
			};
			return allDataPercent;
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { 'top': NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
		percentSwitch: function () {
			if(!this.percentSwitch) {
				this.$refs.regPercent.clear();
				this.regChart(this.termly);
			} else {
				this.$refs.regLine.clear();
				this.regChart(this.termly);
			}
		},
	},
	computed: {
		...mapGetters([
			'getDomain',
			'getDialogShow',
			'getStatusDialog',
			'getOperatorDefaultRouter',
			'getOpenAutoSearch',
		]),
	},
	created() {},
};
</script>
